<template>
  <a-card>
    <a-descriptions bordered :column="1">
      <a-descriptions-item label="银行账户开户银行所在省份编码">{{form.prov_code}}</a-descriptions-item>
      <a-descriptions-item label="银行账户开户银行所在区域编码">{{form.area_code}}</a-descriptions-item>
      <a-descriptions-item label="银行账户类型">{{form.bank_acct_type==1?'公立账户':'私立账户'}}</a-descriptions-item>
      <a-descriptions-item label="银行卡号">{{form.card_id}}</a-descriptions-item>
      <a-descriptions-item label="银行卡对应的户名">{{form.card_name}}</a-descriptions-item>
      <a-descriptions-item label="手机号">{{form.tel_no}}</a-descriptions-item>
    </a-descriptions>
  </a-card>
</template>
<script>
import { ref,reactive} from "vue";
import { getBlankConfig } from "@/api/system";
export default {
  setup() {
    const form = ref({ });
    const initData = async () => {
      let result = await getBlankConfig().then((res) => res.data).catch((error) => error);
      form.value = result.data;
    };
    initData();
    return {
      form
    };
  },
};
</script>
